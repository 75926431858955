(function() {
    'use strict';
    angular
        .module('investhryApp')
        .factory('FileManagement',['$http', function($http){
        return {
                saveFile: function(file,filename,onsuccess,onerror){
                    var resourceUrl =  'api/FileManagement';
                    var fd = new FormData();
                    fd.append('file', file);
                    fd.append('filename',filename);
                    $http.post(resourceUrl, fd, {
                    transformRequest : angular.identity,
                    headers : {
                    'Content-Type' : undefined
                    }
                    }).success(function() {
                    console.log('success');
                    if (onsuccess!==undefined){
                        onsuccess();
                    }
                    }).error(function() {
                    console.log('error');
                        if (onerror!==undefined){
                            onerror();
                        }
                    });
                }
            }
        }]);
})();
