(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('TowerOfficerProjectDialogController', TowerOfficerProjectDialogController);

    TowerOfficerProjectDialogController.$inject = ['$scope','$stateParams','GetServiceLog','serviceFormFieldDataCollection','ServiceFormAttachments','$uibModalInstance','downloadserviceformattachment','ProjectAction','projectService'];

    function TowerOfficerProjectDialogController ($scope,$stateParams,GetServiceLog,serviceFormFieldDataCollection,ServiceFormAttachments,$uibModalInstance,downloadserviceformattachment,ProjectAction,projectService) {
        var vm = this;
        vm.projects=[];
        vm.downloadFile=downloadFile;
        vm.addComment=addComment;
        vm.addClarification=addClarification;
        vm.assignServiceFee=assignServiceFee;
        vm.status=$stateParams.action;
        vm.projectAttachemnt={};
        vm.pdfconverter= pdfconverter;

        $scope.file = {};

        loadProject();

        function loadProject() {
            vm.project=projectService;
           switch (vm.status){
               case 'service-logs':
                   viewlogs(vm.project);
                   break;
               case 'view-form':
                   loadFilledForm(vm.project);
                   break;
           }
        }

        function pdfconverter() {
            var element = document.getElementById('pdf_logs');
            html2pdf(element,{
                margin:  0.5,
                html2canvas: { dpi:192, letterRendering: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            });
        };

        function viewlogs(project) {
            vm.project=project;
            GetServiceLog.query({projectid:project.projectid,serviceid:project.serviceid},function (result) {
                vm.ServiceLog=result;
            });
        }

        function loadFilledForm(project) {
            vm.project=project;
            vm.serviceFormFieldData= serviceFormFieldDataCollection.query({serviceid:project.serviceid, projectid:project.projectid});
            vm.serviceformattachments=ServiceFormAttachments.query({id:"1",serviceid:project.serviceid, projectid:project.projectid});
        }

        function addComment() {
            ProjectAction.comment(vm.project,vm.projectAttachemnt,vm.comments,onsuccess,onerror);
        }

        function addClarification(role) {
            ProjectAction.clarification(vm.project,vm.projectAttachemnt,vm.comments, role, onsuccess,onerror);
        }

        function assignServiceFee(){
            ProjectAction.assignFee(vm.project,vm.projectAttachemnt,vm.comments,vm.serviceFee,onsuccess,onerror);
        }

        function downloadFile(serverfilename,downloadfilename)
        {
            downloadserviceformattachment.download(serverfilename,downloadfilename)
                .then(function(success) {
                    console.log('success : ' + success);
                }, function(error) {
                    console.log('error : ' + error);
                });
        }

        vm.clear=function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onsuccess(result) {
            $uibModalInstance.close();
        }

        function onerror() {

        }
    }
})();
