(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$timeout', '$scope', 'Principal', 'LoginService', '$state', '$uibModal', 'Visitor_count', 'downloadserviceformattachment', 'DraftPolicy', 'PolicyDetails','entity','HomePageCountOutput'];

    function HomeController ($timeout, $scope, Principal, LoginService, $state, $uibModal, Visitor_count, downloadserviceformattachment, DraftPolicy, PolicyDetails,entity,HomePageCountOutput) {
        var vm = this;
        loadAll();
        vm.city = 'gurugram';
        vm.showModal=entity;
        vm.isModelOpenedPreviously = sessionStorage.getItem('modalOpened');
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.save = save;
        vm.draftPolicy = {};
        vm.draftPolicy.isReplied = false;
        vm.draftPolicy.policy = 'Policy 1';
        vm.homePageCountOutputs = {};

        $scope.eodbModal = {};
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        // if(vm.showModal){
        //     if (!vm.isModelOpenedPreviously) {
        //         UIkit.modal('#advModal').show();
        //     }
        //
        // }
        // $timeout(function (){
        //     UIkit.modal('#advModal').hide();
        //     sessionStorage.setItem('modalOpened', true);
        // },10000);

        if(vm.showModal) {
            UIkit.modal('#advModal').show();
        }

        $timeout(function (){
            UIkit.modal('#advModal').hide();
        },10000);

        getAccount();
        //
        // vm.clear = function () {
        //     UIkit.modal('#advModal').hide();
        //    sessionStorage.setItem('modalOpened', true);
        // };

        // code for banner on home screen
        //  var modalins =$uibModal.open({
        //     animation: $scope.animationsEnabled,
        //     imageUrl: 'content/images/bandash.jpg',
        //     size: 'lg'
        // });

        function getAccount() {
            // setTimeout(function(){
            //     modalins.close();
            // }, 8000);

            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
            HomePageCountOutput.query(function(result) {
                vm.homePageCountOutputs = result[0];
                vm.searchQuery = null;
            })
        }

        function register () {
            $state.go('register');
        }

        function loadAll() {
            PolicyDetails.query(function(result) {
                vm.policyDetails = result;
                vm.searchQuery = null;
            });
        }

        vm.downloadAdvertisement = function(serverfilename,downloadfilename){
            downloadserviceformattachment.download(serverfilename, downloadfilename);
        };

        function save() {
            DraftPolicy.save(vm.draftPolicy,onSaveSuccess,onSaveError);
        }

        function onSaveSuccess() {
            UIkit.modal('#draft_policy').hide();
            location.reload();
        }

        function onSaveError() {

        }

        $scope.setTempItem = function(item) {
            $scope.policyName = item.name;
            $scope.serverFileName = item.file;
        };
        vm.commingSoonPages = function () {
            alert("Coming Soon");
        }
    }
})();
