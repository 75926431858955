(function () {
    'use strict';

    angular
        .module('investhryApp')
        .controller('AddCAFexciseController', AddCAFexciseController);

    AddCAFexciseController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'AddCAF', 'project', 'District', 'cAFAttachment', 'FileManagement', 'CAFAttachment', 'cafAttachments', 'DistrictVillageBlockMapping', 'CheckContent', 'ExtendedUserInformation'];

    function AddCAFexciseController($scope, Principal, LoginService, $state, AddCAF, project, District, cAFAttachment, FileManagement, CAFAttachment, cafAttachments, DistrictVillageBlockMapping, CheckContent, ExtendedUserInformation) {
        var vm = this;

        vm.excisereq = project;
        vm.cAFAttachment = cAFAttachment;
        vm.cAFAttachmentSign = "";
        $scope.file = {};
        vm.cafAttachments = cafAttachments;
        vm.investorsign = null;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.excisereq.id == null) {
                    vm.excisereq.firstname = account.firstName;
                    vm.excisereq.lastname = account.lastName;
                    vm.excisereq.emailprimary = account.email;
                    ExtendedUserInformation.get({id: account.id}, function (result) {
                        vm.excisereq.mobilenumber = result.userMobile;
                    });
                }
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register() {
            $state.go('register');
        }


        vm.districts = [];
        loadAllDistrict();

        function loadAllDistrict() {
            District.query(function (result) {
                vm.districtstemp = result;
                angular.forEach(vm.districtstemp, function (value, key) {
                    if ((value.districtname !== 'Narnaul' && value.districtname !== 'Chandigarh HQ')) {
                        vm.districts.push(value);
                    }
                });
                vm.searchQuery = null;
            });
        }

        vm.getVillages = function (districtName) {
            DistrictVillageBlockMapping.query({id: '1', districtName: districtName}, function (result) {
                vm.villages = result;
                vm.searchQuery = null;
                vm.excisereq.village = null;
                vm.excisereq.blockCategory = null;
            });
        };

        vm.setBlockCategory = function (villageName, villageList) {
            vm.villageNames = [];
            angular.forEach(villageList, function (value, key) {
                vm.villageNames.push(value.village);
            });
            if (vm.villageNames.indexOf(villageName) !== -1) {
                vm.invalidVillage = false;
                vm.excisereq.blockCategory = villageList[vm.villageNames.indexOf(villageName)].category;
            } else {
                vm.invalidVillage = true;
            }
        };


        vm.changeInCost = function () {
            var projectCostInCrore = vm.project_cost_in_crore || '00';
            var projectCostInLacs = vm.project_cost_in_lacs || '00';
            var projectCostInThousands = vm.project_cost_in_thousands || '00';
            if (vm.project_cost_in_lacs < 10) {
                projectCostInLacs = '0' + vm.project_cost_in_lacs;
            }
            if (vm.project_cost_in_thousands < 10) {
                projectCostInThousands = '0' + vm.project_cost_in_thousands;
            }
            return vm.total_project_cost = projectCostInCrore + "." + projectCostInLacs + "" + projectCostInThousands;
        }

        var calculateCostInCrore = function () {
            return parseInt(vm.excisereq.total_project_cost.toString().split('.')[0]);
        }

        var calculateCostInLacs = function () {
            if (vm.excisereq.total_project_cost.toString().indexOf('.') != -1) {
                var num = parseInt(vm.excisereq.total_project_cost * (10000000));
                var lac = parseInt((num / 100000) % 100);
                return lac;
            }
            return 0;
        }

        var calculateCostInThousands = function () {
            if (vm.excisereq.total_project_cost.toString().indexOf('.') != -1) {
                var num = parseInt(vm.excisereq.total_project_cost * (10000000));
                var th = parseInt((num / 1000) % 100);
                return th;
            }
            return 0;
        }

        vm.project_cost_in_crore = calculateCostInCrore();
        vm.project_cost_in_lacs = calculateCostInLacs();
        vm.project_cost_in_thousands = calculateCostInThousands();

        var projectCostInCrore = vm.project_cost_in_crore || '00';
        var projectCostInLacs = vm.project_cost_in_lacs || '00';
        var projectCostInThousands = vm.project_cost_in_thousands || '00';
        if (vm.project_cost_in_lacs < 10) {
            projectCostInLacs = '0' + vm.project_cost_in_lacs;
        }
        if (vm.project_cost_in_thousands < 10) {
            projectCostInThousands = '0' + vm.project_cost_in_thousands;
        }
        vm.total_project_cost = projectCostInCrore + "." + projectCostInLacs + "" + projectCostInThousands;


        vm.onsaveProject = onsaveProject;

        function onsaveProject(flag) {
            vm.isSaving = true;
            vm.excisereq.total_project_cost = vm.total_project_cost;
            if (flag == 'Draft') {
                vm.excisereq.isdraft = true;
            } else if (flag == 'Final') {
                vm.excisereq.isdraft = false;
            }
            if (angular.isDefined(vm.cAFAttachmentSign.investorsign)) {
                var sign = vm.cAFAttachmentSign.investorsign;
                var fileNameArray = sign.name.split(".");

                if (fileNameArray.length < 3) {
                    if (fileNameArray[0] != "") {
                        var idxDot = sign.name.lastIndexOf(".") + 1;
                        var extFile = sign.name.substr(idxDot, sign.length).toLowerCase();

                        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                            saveCAF();
                        } else {
                            alert("Only jpg/jpeg and png files are allowed!");
                        }
                    } else {
                        alert("Invalid File Name");
                    }
                } else {
                    alert('Signature File should be in appropriate format')
                }
            } else {
                saveCAF();
            }

        }


        function onSaveProjectSuccess(result) {
            uploadFile(result.id);
            alert('CAF Saved');
            vm.isSaving = false;
        }

        function onSaveProjectError() {
            alert('You can apply only once for excise caf');
            vm.isSaving = false;
        }

        function uploadFile(projectid) {
            if (angular.isDefined(vm.cAFAttachmentSign.investorsign)) {
                vm.cAFAttachment.docName = "Signature";
                vm.cAFAttachment.projectid = projectid;
                CAFAttachment.save(vm.cAFAttachment, onSaveCAFAttachmentSignSuccess, onSaveCAFAttachmentSignError);
            }

            function onSaveCAFAttachmentSignSuccess(result) {
                $scope.$emit('investhryApp:cAFAttachmentUpdate', result);
                vm.cafattachresult = result;
                var cafattachmentid = result.id;

                var filename = result.id;
                var file = vm.cAFAttachmentSign.investorsign;
                FileManagement.saveFile(file, filename, onFileSaveSuccess, onFileSaveError(cafattachmentid));
                vm.isSaving = false;
            }

            function onFileSaveSuccess(result) {
                $scope.$emit('investhryApp:cAFAttachmentUpdate', vm.cafattachresult);
                var CAFAttachmentResultObject = vm.cafattachresult;
                var filename = CAFAttachmentResultObject.id;
                var file = vm.cAFAttachmentSign.investorsign;
                var fileNameArray = file.name.split(".");
                var fileextension = "";
                if ((fileNameArray.length > 1)) {
                    fileextension = fileNameArray[(fileNameArray.length - 1)];
                }

                var inputFileName = CAFAttachmentResultObject.id;
                if (fileextension !== "") {
                    inputFileName = inputFileName + "." + fileextension;
                }
                CAFAttachmentResultObject.fileName = inputFileName;
                CAFAttachment.update(CAFAttachmentResultObject);
            }

            function onFileSaveError(cafattachmentid) {
                CAFAttachment.delete({id: cafattachmentid});

            }

            function onSaveCAFAttachmentSignError() {
                vm.isSaving = false;
            }
        }


        for (var attachmentcounter = 0; attachmentcounter < vm.cafAttachments.length; attachmentcounter++) {
            if (vm.cafAttachments[0].docName == "Signature") {
                vm.investorsign = vm.cafAttachments[0];
            }
        }

        function onUpdateProjectSuccess(result) {
            replaceUploadedSign(result);
            alert('CAF Edited');

            vm.isSaving = false;
        }

        function onUpdateProjectError(result) {
            alert('CAF Not Edited');
            vm.isSaving = false;
        }

        function replaceUploadedSign(result) {
            if (angular.isDefined(vm.cAFAttachmentSign.investorsign)) {
                if (vm.investorsign !== null) {
                    var file = vm.cAFAttachmentSign.investorsign;
                    var filename = vm.investorsign.id;
                    FileManagement.saveFile(file, filename, onreplaceUploadedSignSuccess, onreplaceUploadedSignError);
                } else {
                    uploadFile(result.id);
                }
            }

            function onreplaceUploadedSignSuccess() {
                var file = vm.cAFAttachmentSign.investorsign;
                var filename = vm.investorsign.id;
                var fileNameArray = file.name.split(".");
                var fileextension = "";
                if ((fileNameArray.length > 1)) {
                    fileextension = fileNameArray[(fileNameArray.length - 1)];
                }

                var inputFileName = vm.investorsign.id;
                if (fileextension !== "") {
                    inputFileName = inputFileName + "." + fileextension;
                }
                vm.investorsign.fileName = inputFileName;
                CAFAttachment.update(vm.investorsign, onUpdateCAFAttachmentSignSuccess, onUpdateCAFAttachmentSignError);
                vm.isSaving = false;
            }

            function onreplaceUploadedSignError() {
                vm.isSaving = false;
            }

            function onUpdateCAFAttachmentSignSuccess() {
                vm.isSaving = false;
            }

            function onUpdateCAFAttachmentSignError() {
                vm.isSaving = false;
            }
        }

        function saveCAF() {
            if (vm.excisereq.id !== null) {
                AddCAF.update(vm.excisereq, onUpdateProjectSuccess, onUpdateProjectError);
            } else {
                AddCAF.save(vm.excisereq, onSaveProjectSuccess, onSaveProjectError);
            }
            $state.go('listproject');
        }

        $('input').each(function () {
            CheckContent.check($(this))
        });
        $('textarea').each(function () {
            CheckContent.check($(this))
        });

    }
})();
