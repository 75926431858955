(function() {
    'use strict';

    angular
        .module('investhryApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance','Principal','CheckContent'];

    function LoginController ($rootScope, $state, $timeout, Auth, $uibModalInstance,Principal,CheckContent) {
        var vm = this;
        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.roles=[];
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $('input').each(function(){ CheckContent.check($(this))});
        $('textarea').each(function() { CheckContent.check($(this))});

        $timeout(function (){angular.element('#username').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login (event) {
            var encPassword = encryptPassword(vm.password);
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: encPassword,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                $uibModalInstance.close();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }
                getCurrentRole();
                $rootScope.$broadcast('authenticationSuccess');
                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        function register () {
            $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword () {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
        function getCurrentRole(){
        Principal.identity().then(function(account){
        if(account.authorities.indexOf('ROLE_USER')>-1 && (account.authorities.indexOf('ROLE_ADMIN')<0 || account.authorities.indexOf('ROLE_SUB_ADMIN')<0)){
          $state.go('listproject', {}, { reload: true });
        }
        else if(account.authorities.indexOf('ROLE_NODAL_OFFICER')>-1){
            $state.go("nodalofficerviewprojectdetail", {status:'Pending'}, { reload: true });
        }
        else if(account.authorities.indexOf('ROLE_TOWER_DC')>-1){
            $state.go("projects", {}, { reload: true });
        }
        else
        $state.go('home', {}, { reload: true });
        });
        }

        function encryptPassword(plainString)
        {
        	var iv = "a407e444cd6179be5ced97caf0ff11cb";
        	var salt = "a826254e90f626b011eb21aca9a79b65";
        	var keySize = 128;
        	var iterationCount = 10000;
        	var passPhrase = "NAimIodPNkqOyHQXmEpoFA==Sdty=jHY";
        	var aesUtil = new AesUtil(keySize, iterationCount);
        	var encryptedString = aesUtil.encrypt(salt, iv, passPhrase, plainString);
        	return encryptedString;
        }
    }
})();
